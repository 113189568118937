.typed-text {
  display: inline-block;

  &.typed-text--cursor {
    &:after {
      content: '|';
      font-size: 1.2em;
      -webkit-animation: blink 0.7s infinite;
      animation: blink 0.7s infinite;
      position: relative;
      right: 6px;
    }
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
