/*! -- Stack Icons -- */

@use "sass:math";

.icon{
	&:not(.block){
		display: inline-block;
	}
	&.icon--xs{
		font-size: 1.14285714285714em;
	}
}

.text-block{
	.icon{
		+h4{
			margin-top: 0.6842105263158em;
		}
	}
}

.imagebg{
	.icon{
		color: #fff;
	}
}

p{
	.material-icons{
		font-size: 1em;
		&:first-child{
			margin-right: math.div($base-line-height, 6);
		}
	}
}

.material-icons{
	font-family: 'Material Icons' !important;
}

.icon-circle{
	position: relative;
	display: inline-block;
	width: $base-line-height*2;
	height: $base-line-height*2;
	border-radius: 50%;
	border: 1px solid #ececec;
	background: $color-bg-secondary;
	text-align: center;
	i{
		font-size: 24px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
		left: 0;

	}
}