form[action*='createsend.com'] {
  * {
    @include transition(0.3s, linear);
    opacity: 0;
  }

  &.form--active {
    * {
      opacity: 1;
    }
  }

  .input-checkbox {
    +br {
      display: none;
    }
  }

  &.no-labels {
    label {
      display: none
    }
  }

  br {
    display: none;
  }

  p {
    >label {
      &:first-child {
        margin-bottom: $base-line-height * 0.5;
      }
    }
  }
}

form[action*='list-manage.com'] {
  h2 {
    @extend h4;
  }

  .input-group {
    ul {
      overflow: hidden;

      li {
        float: left;
      }
    }
  }

  * {
    @include transition(0.3s, linear);
    opacity: 0;
  }

  &.form--active {
    * {
      opacity: 1;
    }
  }

  &.no-labels {
    label {
      display: none
    }
  }

  .small-meta {
    font-size: 0.5em;
  }
}
