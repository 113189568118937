.icon{
	line-height: 1em;
	font-size: $icon-md-size;
}

.icon--xs{
	font-size: 1em;
}

.icon--sm{
	font-size: $icon-sm-size;
}

.icon--lg{
	font-size: $icon-lg-size;
}