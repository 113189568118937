/*! -- Stack Sections -- */

section{
	&:not(.unpad):not(.imagebg):not([class*='bg--']):not(.imageblock):not(.unpad--bottom):not(.border--bottom):not(.space--xxs){
		+ section:not(.unpad):not(.imagebg):not([class*='bg--']):not(.imageblock):not(.border--bottom):not(.space--xxs){
			padding-top: 0;
		}
	}
	&:not(.unpad):not(.imagebg):not([class*='bg--']):not(.imageblock):not(.unpad--bottom):not(.border--bottom){
		+footer:not(.unpad):not(.imagebg):not([class*='bg--']):not(.imageblock):not(.unpad--bottom):not(.border--bottom){
			padding-top: 0;
		}
	}
	&:not(.imagebg):not([class*='bg-']){
		+section{
			&.bg--secondary{
				border-top: 1px solid darken($color-bg-secondary, 6%);
			}
		}
	}
	&.bg--secondary{
		&:not(.imagebg){
			+section:not(.imagebg):not([class*='bg-']){
				border-top: 1px solid darken($color-bg-secondary, 6%);
			}
		}
		&:not(.unpad):not(.imageblock):not(.unpad--bottom):not(.border--bottom){
			+section.bg--secondary{
				padding-top: 0;
			}
		}
		+footer{
			&:not(.bg--dark):not(.bg--secondary){
				border-top: 1px solid darken($color-bg-secondary, 6%);
			}
		}
	}
	&.bg--dark{
		+section.bg--dark{
			padding-top: 0;
		}
		&:last-of-type{
			+footer.bg--dark{
				background: darken($color-dark, 4%)
			}
		}
	}
	&.border--bottom{
		&:not([data-gradient-bg]){
			border-bottom: 1px solid #ececec;
		}
	}
	&.unpad{
		overflow: hidden;
	}
	&:not([class*='bg--']){
		+footer{
			&.bg--secondary{
				border-top: 1px solid darken($color-bg-secondary, 6%);
			}
		}
	}
	&.text-center{
		div[class*='col-']{
			&:first-child:last-child{
				margin: 0 auto;
				float: none;
			}
		}
	}
}

.section--overlap{
	z-index: 2;
	position: relative;
}