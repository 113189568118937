/*! -- Stack Cards -- */

.card__top{
	position: relative;
	overflow: hidden;
}

.card__action{
	a{
		color: $color-body;
		i{
			color: $color-dark;
			font-size: 1em;
		}
		span{
			position: relative;
			bottom: 2px;
		}
		text-decoration: none;
		@include transition(0.3s,ease);
		&:not(:hover){
			opacity: .5;
		}
	}
}

.card p a{
	text-decoration: none;
}

.masonry__item{
	.card.boxed{
		margin-bottom: 0;
	}
}

.card-1{
	.card__avatar{
		img{
			max-height: $base-line-height*1.5;
			margin-right: $base-line-height*0.25;
			margin-bottom: 0;
		}
		margin-bottom: $base-line-height*0.5;
	}
	.card__avatar, .card__meta{
		display: inline-block;
	}
	.card__meta{
		float: right;
	}
	.card__body{
		img{
			margin-bottom: $base-line-height*0.5;
		}
		h4{
			margin-bottom: 0.342105263157895em;
		}
		border-bottom: 1px solid #ececec;
		padding-bottom: $base-line-height*0.5;
		margin-bottom: $base-line-height*0.5;
	}
}

.bg--dark{
	.card-1{
		.card__body{
			border-color: lighten($color-dark, 10%)
		}
	}
}

@media all and (max-width: 767px){
	.card{
		.list-inline:not(.social-list){
			li{
				display: inline-block;
				&:not(:first-child){
					margin-left: $base-line-height*0.5;
				}
			}
		}
	}
}

.card-2{
	.card__top{
		img{
			border-radius: 6px 6px 0 0;
		}
	}
	.card__body{
		h4{
			margin: 0;
		}
		p{
			margin-top: $base-line-height*0.5;
		}
		border-bottom: 1px solid #ececec;
		padding-bottom: $base-line-height;
	}
	.card__bottom{
		overflow: hidden;
		> div{
			display: inline-block;
			width: 50%;
			float: left;
		}
		.h6{
			margin-bottom: 0.541666666666667em;
		}
	}
	.card__body, .card__bottom{
		padding: $base-line-height;
		border: 1px solid #ececec;
	}
	.card__body{
		border-bottom: none;
		border-top: none;
	}
	.card__bottom{
		border-radius: 0 0 6px 6px;
		padding: $base-line-height*0.5 $base-line-height;
	}
	.card__action{
		i{
			font-size: $h3-size;
		}
	}
}

.bg--dark{
	.card-2{
		.card__body, .card__bottom{
			border-color: lighten($color-dark, 10%)
		}
	}
}