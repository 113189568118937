/*! -- Stack Typography -- */

@media all and (max-width: 1024px){
	html{
		font-size: 80%;
	}
}

h1,.h1{
	&:not(:last-child){
		margin-bottom: 0.59090909090909em;
	}
	letter-spacing: -.01em;
}

@media all and (min-width: 768px){
	h1,.h1{
		&.h1--large{
			font-weight: 200;
			font-size: 4.428571428571429em;
			line-height: 1.048387096774194em;
			&:not(:last-child){
				margin-bottom: 0.419354838709677em;
			}
			&.type--uppercase{
				letter-spacing: 10px;
				margin-right: -10px;
			}
			+p.lead{
				margin-top: 2.052631578947368em;
			}
		}
	}
}

h2,.h2{
	margin-bottom: 0.78787878787879em;
}

h3,.h3{
	margin-bottom: 1.04em;
	strong{
		font-weight: 400;
	}
}

blockquote{
	&:not(:last-child){
		margin-bottom: 1.04em;
	}
	font-family: 'Merriweather', serif;
	font-style: italic;
	font-weight: 300;
	> p{
		font-size: 1em !important;
	}
}

h4,.h4{
	margin-bottom: 1.36842105263158em;
	font-weight: 400;
	&.inline-block{
		+.h4{
			&.inline-block:not(.typed-text){
				margin-left: 0.68421052631579em;
			}
		}
	}
}

h5,.h5{
	font-weight: 600;
	&:not(:last-child){
		margin-bottom: $base-line-height;
	}
}

h6,.h6{
	&:not(:last-child){
		margin-bottom: 2.16666666666667em;
	}
	font-weight: 700;
	&.type--uppercase{
		letter-spacing: 1px;
		margin-right: -1px;
	}
}

span{
	&.h1,&.h2,&.h3,&.h4,&.h5,&.h6{
		&:not(.inline-block){
			display: block;
		}
	}
}

b{
	font-weight: 600;
}

hr{
	border-color: #ECECEC;
}

.bg--dark{
	hr{
		border-color: lighten($color-dark, 20%)
	}
}

[class*='bg-']:not(.bg--white):not(.bg--secondary), [class*='imagebg']:not(.image--light){
	p{
		opacity: .9;
	}
}

.lead{
	font-weight: 400;
	color: lighten($color-body, 10%);
	&:not(:last-child){
		margin-bottom: 1.36842105263158em;
	}
	+.btn{
		&:last-child{
			margin-top: $base-line-height*0.5;
		}
	}
}

p{
	&:last-child{
		margin-bottom: 0;
	}
	strong{
		color: $color-dark;
	}
}

pre{
	padding: $base-line-height*0.5;
	background: $color-bg-secondary;
	border: 1px solid #ececec;
	border-radius: 6px;
	line-height: 20px;
	max-height: 500px;
}

.bg--secondary{
	> pre{
		background: darken($color-bg-secondary, 2%);
		border-color: #ddd;
	}
}

.text-block{
	margin-bottom: $base-line-height;
	h2,.h2{
		margin-bottom: 0.3939393939394em;
	}
	h5,.h5{
		margin: 0;
	}
	h4,.h4{
		&:not(:last-child){
			margin-bottom: 0.3421052631579em;
		}
	}
	h3,.h3{
		margin-bottom: 0.52em;
	}
}

@media all and (min-width: 768px){
	div[class*='col-']{
		.text-block{
			+.text-block{
				margin-top: $base-line-height*2;
			}
		}
	}
}

.heading-block{
	margin-bottom: $base-line-height*2;
	h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{
		margin-bottom: 0;
	}
}