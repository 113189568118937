/*! -- Stack Footers -- */

footer{
	.type--fine-print{
		&:not(p){
			opacity: .9;
		}
	}
	a{
		font-weight: normal;
	}
	&:not(.bg--primary):not(.bg--dark){
		a{
			color: $color-body;
		}
	}
	a.type--fine-print{
		&:not(:first-child){
			margin-left: $base-line-height;
		}
	}
	.logo{
		max-height: $base-line-height*.9;
	}
	ul{
		&:not(.list-inline):not(.slides){
			> li{
				line-height: $base-line-height*1.25 !important;
			}
		}
	}
	&.footer-2{
		.row{
			&:last-child{
				margin-top: $base-line-height*2;
			}
		}
	}
	&.footer-5{
		.row{
			[class*='col-']{
				>div{
					&:nth-last-child(2){
						margin: $base-line-height*0.5;
						.type--fine-print{
							opacity: 1;
						}
					}
				}
			}
		}
	}
	&.footer-6{
		.footer__lower{
			background: $color-bg-secondary;
			margin-top: $base-line-height;
			padding: $base-line-height 0;
		}
		&.bg--dark{
			.footer__lower{
				background: darken($color-dark, 5%);
			}
		}
		&.bg--secondary{
			.footer__lower{
				background: darken($color-bg-secondary, 2%);
			}
		}
		&.bg--primary{
			.footer__lower{
				background: darken($color-primary, 5%);
			}
		}
	}
	&.footer-7{
		padding: $base-line-height 0;
	}
}

@media all and (min-width: 768px){
	footer{
		.list-inline{
			+.btn{
				position: relative;
				bottom: 4px;
			}
		}
		.logo{
			margin: 0;
			&:not(:last-child){
				margin-right: $base-line-height;
			}
		}
		&.footer-3{
			.logo{
				+ul{
					position: relative;
					top: 5px;
					a{
						color: $color-dark;
					}
				}
			}
			.social-list{
				position: relative;
				top: 9px;
			}
			.row:last-child{
				margin-top: $base-line-height;
			}
		}
		&.footer-4{
			.logo{
				+span{
					position: relative;
				}
			}
			form{
				position: relative;
				bottom: 4px;
			}
			.row{
				&:last-child{
					margin-top: $base-line-height;
				}
			}
		}
		&.imagebg:not(.image--light){
			span,p,a{
				color: #fff;
			}
		}
	}
	.footer-1{
		.type--fine-print{
			position: relative;
			top: 2px;
		}
	}
}

@media all and (max-width: 767px){
	footer{
		.social-list{
			margin: $base-line-height 0;
		}
	}
	.footer-4{
		form{
			margin: $base-line-height 0;
		}
		.logo{
			margin: 0;
		}
	}
	.footer-6{
		.footer__lower{
			.social-list{
				margin-top: $base-line-height;
			}
		}
	}
	.footer-7{
		.social-list{
			margin-top: $base-line-height;
		}
	}
	.footer-1{
		.social-list{
			margin-top: $base-line-height;
		}
		.logo{
			display: block;
			margin-top: $base-line-height;
			margin-bottom: $base-line-height*0.5;
			margin-left: auto;
			margin-right: auto;
		}
	}
}
