.breadcrumb {
  padding: 0;
  margin: 0;
  background: none;
  display: inline-block;

  li {
    font-size: $breadcrumb-font-size;

    @if($breadcrumbs-uppercase==true) {
      text-transform: uppercase;
    }
  }

  li+li:before {
    padding: 0 $breadcrumb-item-padding;
  }
}
