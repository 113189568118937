/*! -- Stack Alerts -- */

.alert{
	border-radius: 6px;
	&:not(:last-child){
		margin-bottom: $base-line-height;
	}
	.alert__close{
		font-size: $h4-size;
	}
}