/*! -- Stack Nav Side Menu -- */

.notification.side-menu {
  z-index: 9999;
  background: $color-bg-site;
  padding-top: $base-line-height;
  margin: 0;
  height: 100%;
  @extend .box-shadow-wide;
  width: $base-line-height*15;

  .side-menu__module {
    overflow: hidden;
    padding: $base-line-height*1.5;

    +hr {
      &:not(:last-child) {
        margin: 0;
      }
    }
  }

  .menu-vertical {
    li {
      a {
        font-size: $h4-size;
        line-height: $h4-line-height;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .btn {
    &:not(:last-child) {
      margin-bottom: $base-line-height;
    }

    +ul.list--loose {
      margin-top: $base-line-height*0.5;
    }
  }

  .notification-close-cross {
    top: $base-line-height*0.5;
    right: $base-line-height*1.5;
    margin-top: 8px;
  }

  .social-list {
    &:not(:first-child) {
      margin-top: $base-line-height;
    }
  }
}

.menu-toggle.pos-fixed {
  position: fixed;
  right: $base-line-height * 3;
  animation: fadeInDown .3s ease forwards;
}

@media all and (min-width: 768px) {
  .side-menu {
    .side-menu__module {
      span.type--fine-print {
        position: relative;
        top: 8px;
      }
    }
  }
}

@media all and (max-width: 767px) {
  .side-menu {
    width: 100%;

    .side-menu__module {

      .float-right,
      .float-left {
        display: block;
      }
    }
  }
}
