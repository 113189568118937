/*! -- Stack Covers -- */

.cover{
	.typed-headline{
		margin-bottom: $base-line-height*2;
		.type--bold{
			font-weight: 400;
		}
	}
	.triptych{
		margin-top: $base-line-height*2;
	}
	.btn{
		+.modal-instance{
			margin-top: $base-line-height;
		}
	}
	h3,.h3{
		+form{
			margin-top: $base-line-height*1.5;
		}
	}
	.lead{
		+.boxed{
			margin-top: $base-line-height*2;
		}
	}
	&.unpad--bottom{
		overflow: hidden;
	}
	.modal-instance.block{
		&:not(:first-child):not(:last-child){
			margin: $base-line-height*1.5 0;
		}
	}
	.pos-bottom{
		margin-bottom: $base-line-height;
	}
}

@media all and (min-width: 768px){
	.cover{
		.row{
			.row{
				&:last-child{
					margin-top: $base-line-height*3;
				}
			}
		}
	}
}

@media all and (max-width: 767px){
	.cover{
		.pos-bottom.pos-absolute{
			position: relative;
			margin-top: $base-line-height;
		}
	}
}

@media all and (max-width: 990px){
	.cover{
		.mt--3{
			margin-top: 0;
		}
	}
}

@media all and (min-width: 768px){
	.cover{
		.triptych{
			margin-top: $base-line-height*4;
		}
	}
}

.cover-fullscreen{
	min-height: 100vh;
	&.slider[data-paging="true"]{
		.slides{
			.flickity-slider{
				> li{
					height: 100vh;
				}
			}
		}
		.pos-bottom{
			bottom: $base-line-height*3;
			width: 100%;
		}
		h4{
			margin: 0;
		}
	}
}

.cover-slider{
	overflow: hidden;
	.slider{
		position: relative;
		width: 100%;
		pointer-events: none;
		&:nth-child(1){
			transform: translateY(-30%);
		}
		&:nth-child(2){
			transform: translateY(-25%);
		}
		&:nth-child(3){
			transform: translateY(-20%);
		}
		.slide{
			opacity: .05;
		}
	}
	.typed-headline{
		margin: 0;
		+.btn{
			margin-top: $base-line-height*0.5;
		}
	}
	.slider-container{
		transform: rotateZ(-30deg) translateY(-10%);
		-webkit-transform: rotateZ(-30deg) translateY(-10%);
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
	}
}

// Desktop
@media all and (min-width: 768px){
	.cover-slider{
		+.cta-4{
			position: absolute;
			background: none;
			color: #fff;
			border-bottom: none !important;
			width: 100%;
			@include translate3d(0,-100%,0);
			a{
				color: #fff;
				font-weight: 600;
			}
		}
	}
}

.cover-features{
	&.space--lg{
		padding-bottom: $base-line-height * 2;
	}
	.row:nth-child(2){
		margin-top: $base-line-height* 6;
	}
}

@media all and (max-width: 767px){
	.cover-features{
		&.space--lg{
			padding-bottom: $base-line-height;
		}
		.row:nth-child(2){
			margin-top: $base-line-height * 2;
		}
	}
}