@use "sass:math";

section,footer{
	// padding-top: $standard-space-n;
	// padding-bottom: $standard-space-s;
	&.space--xxs{
		padding-top: $standard-space-n*0.25;
		padding-bottom: $standard-space-n*0.25;
	}
	&.space--xs{
		padding-top: $standard-space-n*0.5;
		padding-bottom: $standard-space-n*0.5;
	}
	&.space--sm{
		padding-top: math.div($standard-space-n, 1.5);
		padding-bottom: math.div($standard-space-n, 1.5);
	}
	&.space--md{
		padding-top: $standard-space-n *1.5;
		padding-bottom: $standard-space-n *1.5;
	}
	&.space--lg{
		padding-top: $standard-space-n * 2;
		padding-bottom: $standard-space-n * 2;
	}
	&.space--xlg{
		padding-top: $standard-space-n * 4;
		padding-bottom: $standard-space-s * 4;
	}
	&.space--0{
		padding: 0;
	}
	&.section--even{
		padding-top: $standard-space-s;
		padding-bottom: $standard-space-s;
	}
	&.space-bottom--sm{
		padding-bottom: math.div($standard-space-n, 1.5);
	}
}


@media all and (max-width: 767px){
	section,footer,section.section--even{
		padding: $standard-space-mobile 0;
		&.space--lg,&.space--md{
			padding: $standard-space-mobile 0;
		}
		&.space--xlg{
			padding: $standard-space-mobile * 1.5 0;
		}
	}

}

// Bootstrap nested columns

div[class*='col-'] > div[class*='col-']:first-child{
	padding-left: 0;
}

div[class*='col-'] > div[class*='col-']:last-child{
	padding-right: 0;
}

// Bootstrap six col fix

@media all and (max-width: 767px){
	.col-xs-6:nth-child(odd){
		padding-right: 7.5px;
	}
	.col-xs-6:nth-child(even){
		padding-left: 7.5px;
	}
}

// Spacing Classes

@media all and (min-width: 768px){
	.mt--1{
		margin-top: $base-line-height;
	}
	.mt--2{
		margin-top: $base-line-height*2;
	}
	.mt--3{
		margin-top: $base-line-height*3;
	}
	.mb--1{
		margin-bottom: $base-line-height;
	}
	.mb--2{
		margin-bottom: $base-line-height*2;
	}
	.mb--3{
		margin-bottom: $base-line-height*3;
	}
}

@media all and (max-width: 990px){
	.mt--1,.mt--2{
		margin-top: $base-line-height;
	}
	.mt--3{
		margin-top: $base-line-height*1.5;
	}
}

.unpad{
	padding: 0;
}

.unpad--bottom{
	padding-bottom: 0;
}

.unpad--top{
	padding-top: 0;
}

section{
	&.unpad--bottom{
		padding-bottom: 0;
	}
	&.unpad{
		padding: 0;
	}
	&.unpad--top{
		padding-top: 0;
	}
}

.unmarg--bottom{
	margin-bottom: 0;
}

.unmarg{
	margin: 0;
}

.unmarg--top{
	margin-top: 0;
}
