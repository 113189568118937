/*! -- Stack Tables -- */

table{
	width: 100%;
	border-collapse: separate;
	th,td{
		padding: $base-line-height*0.5;
	}
	th{
		background: $color-bg-secondary;
		color: $color-dark;
	}
	tr{
		&:not(:last-of-type){
			border-bottom: 1px solid #ececec;
		}
	}
	&.border--round{
		border-radius: 6px;
		border: 1px solid #ececec;
	}
}

.table--alternate-column{
	th{
		&:nth-child(odd){
			background: none;
		}
	}
	td{
		&:nth-child(even){
			background: $color-bg-secondary;
		}
	}
}

.table--alternate-row{
	tbody{
		tr{
			&:nth-child(even){
				background: $color-bg-secondary;
			}
		}
	}
}

.bg--dark{
	table{
		&.border--round{
		border-radius: 6px;
			border: 1px solid lighten($color-dark, 10%);
		}
		th{
			background: lighten($color-dark, 10%);
			color: #fff;
		}
	}
	.table--alternate-row{
		tbody{
			tr{
				&:nth-child(even){
					background: lighten($color-dark, 5%)
				}
			}
		}
	}
}