// MARKUP
//
// <div class="notification col-*" data-animation="from-*">
//   ...
//   <a class="notification-close">Shut It Down</a>
// </div>
//
// MODIFIERS:
// .pos-right,top,left,bottom - position the notification
// .col-* - control the width of the notification
// [data-animation] - from top,bottom,left,right

.notification {
  @include box-shadow($box-shadow-level);
  position: fixed;
  z-index: 99;
  pointer-events: none;
  padding: $default-notification-padding;
  margin: 1em;

  &:not([class*='bg-']) {
    background: #fff;
  }

  &[class*='col-'] {
    min-width: 400px;
  }

  opacity: 0;
  @include transition(0.3s, linear);

  .notification-close-cross {
    position: absolute;
    top: 1em;
    z-index: 99;
    right: 1em;
    cursor: pointer;
    @include transition(0.1s, linear);
    opacity: .7;

    &:before {
      content: '\00D7';
      font-size: 1.5em;
    }

    &:hover {
      opacity: 1;
    }
  }

  &.notification--reveal {
    z-index: 99;
    pointer-events: initial;

    &[data-animation="from-bottom"] {
      @include animation(from-bottom, .3s, linear, 0s, forwards);
    }

    &[data-animation="from-top"] {
      @include animation(from-top, .3s, linear, 0s, forwards);
    }

    &[data-animation="from-left"] {
      @include animation(from-left, .3s, linear, 0s, forwards);
    }

    &[data-animation="from-right"] {
      @include animation(from-right, .3s, linear, 0s, forwards);
    }
  }

  &.notification--dismissed {
    @include animation(fade-out, .4s, linear, 0s, forwards);
    pointer-events: none;
  }
}

.bg--dark+.notification-close-cross {
  &:before {
    color: #fff;
  }
}

a[data-notification-link] {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

@media all and (max-width: 767px) {
  .notification {
    &[class*='col-'] {
      min-width: 0;
    }
  }
}

@keyframes from-bottom {
  0% {
    @include translate3d(0, 100%, 0);
    opacity: 0;
  }

  100% {
    @include translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes from-top {
  0% {
    @include translate3d(0, -100%, 0);
    opacity: 0;
  }

  100% {
    @include translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes from-left {
  0% {
    @include translate3d(-100%, 0, 0);
    opacity: 0;
  }

  100% {
    @include translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes from-right {
  0% {
    @include translate3d(100%, 0, 0);
    opacity: 1;
  }

  100% {
    @include translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
