// MARKUP:
//
// <div class="alert">
//   <div class="alert__body"></div>
//   <div class="alert__close"></div>
// </div>
//
// MODIFIERS:
//
// .bg--error, .bg--success, .bg--primary


.alert{
	overflow: hidden;
	border: 1px solid darken($color-bg-site, 10%);
	padding: $base-line-height*0.5;
	.alert__body, .alert__close{
		display: inline-block;
		user-select: none;
	}
	.alert__body{
		float: left;
	}
	.alert__close{
		float: right;
		cursor: pointer;
	}
	&.bg--error{
		background: lighten($color-error, 40%);
		border-color: $color-error;
		.alert__close{
			color: $color-error;
		}
	}
	&.bg--success{
		background: lighten($color-success, 40%);
		border-color: $color-success;
		.alert__close{
			color: $color-success;
		}
	}
	&.bg--primary{
		background: lighten($color-primary, 40%);
		border-color: $color-primary;
		.alert__body{
			> span{
				color: $color-body;
			}
		}
		.alert__close{
			color: $color-primary;
		}
	}
	&.alert--dismissed{
		display: none;
	}
}