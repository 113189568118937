/*! -- Stack Rules -- */

hr{
	&:first-child{
		margin-top: 0;
	}
	&.short{
		width: $base-line-height*1.5;
		border-color: $color-primary;
	}
	&[data-title]{
		margin: $base-line-height*1.5 0;
		text-align: center;
		&:before{
			content: attr(data-title);
			background: $color-bg-site;
			position: relative;
			bottom:14px;
			padding: $base-line-height*0.5;
			font-style: italic;
		}
	}
}

.bg--dark,.imagebg,.bg--primary{
	hr:not(.short){
		opacity: .3;
	}
}