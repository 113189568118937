//////// Logo Width

@use "sass:math";

$logo-max-height: $base-line-height; // default $base-line-height, change to a pixel value (eg: 50px) for fine control

//////// Colours

$color-bg-site: #FFFFFF;
$color-primary: #4A90E2;
$color-primary-1: #31639C;
$color-primary-2: #465773;
$color-primary-3: #4A90E2;
$color-dark: #252525;
$color-bg-secondary: #FAFAFA;
$color-on-dark: #FFFFFF;

$has-primary-1: true;
$has-primary-2: true;

$color-error: #E23636;
$color-success: #4EBF56;

//////// Typography

$base-font-size: percentage(math.div(14, 16)); // change first value to pixel font size
$base-font-size-px: 14px; // the first value from above;
$base-font-size-mobile: percentage(math.div(13, 16)); // change first value to pixel font size
$base-font-size-mobile-px: 13px; // the first value from above;

$base-line-height: 1.85714285714286em;

$body-font: 'Open Sans';
$heading-font: 'Open Sans';
$button-font: 'Open Sans';
$nav-item-font: 'Open Sans';

$h1-size: 3.14285714285714em;
$h2-size: 2.35714285714286em;
$h3-size: 1.78571428571429em;
$h4-size: 1.35714285714286em;
$h5-size: 1em;
$h6-size: 0.85714285714286em;

$ligatures-active: true;

$h1-line-height: 1.31818181818182em;
$h2-line-height: 1.36363636363636em;
$h3-line-height: 1.5em;
$h4-line-height: 1.368421052631579em;
$h5-line-height: $base-line-height;
$h6-line-height: 2.16666666666667em;

$p-lead-size: 1.35714285714286em;
$p-lead-line-height: 1.68421052631579em;

$blockquote-size: $h3-size;
$blockquote-line-height: $h3-line-height;

$fine-print-size: 0.85714285714286em; 

$ul-line-height: $base-line-height; 

$color-body: #666666;
$color-heading: $color-dark;

$heading-uppercase: false;
$heading-weight: 300;

$body-font-weight: 400;
$strong-font-weight: 600;

//////// Standard Space

$standard-space-n: 4 * $base-line-height;
$standard-space-s: 4 * $base-line-height;
$standard-space-mobile: 3 * $base-line-height;

//////// Boxed Layouts

$boxed-layout-width: 1280px;

//////// Buttons

$buttons-uppercase: false;

$button-radius: 6px;
$button-padding-n: $base-line-height * 0.25;
$button-padding-s: $base-line-height * 0.25;
$button-padding-e: $base-line-height *1.5;
$button-padding-w: $base-line-height *1.5;
$border-width: 1px;
$button-text-size: $h6-size;
$button-font-weight: 700;
$button-spacing: $base-line-height;

//////// Icon

$icon-sm-size: $h2-size;
$icon-md-size: $h1-size;
$icon-lg-size: 5.57142857142857em;

//////// Forms

$input-label-font-size: 1.14285714285714em;
$input-label-font-weight: 400;

$inputs-have-border: true;

$input-padding: $base-line-height*0.25;
$input-border-radius: 6px;
$input-border: 1px solid #ECECEC;
$input-background-color: #FCFCFC;
$input-placeholder-color: lighten($color-body, 30%);
$input-placeholder-size: 1.14285714285714em;

$checkbox-height: $base-line-height;
$checkbox-width: $base-line-height;
$checkbox-border-radius: 6px;
$checkbox-padding: 0;

$radio-height: $base-line-height;
$radio-width: $base-line-height;
$radio-border-radius: 50%;
$radio-padding: 0;

//////// Accordions

$accordion-title-padding: $base-line-height*0.25 $base-line-height*0.5;
$accordion-title-border: 1px solid #ECECEC;
$accordion-title-bg: none;
$accordion-title-bg-active: $color-primary;

$accordion-content-padding: 0;

//////// Breadcrumbs

$breadcrumbs-uppercase: false;
$breadcrumb-item-padding: $base-line-height*0.25; // space between breadcrumbs
$breadcrumb-font-size: 1em;

//////// Tabs

$tab-title-padding: 0 $base-line-height; // space between tabs

//////// Boxes

$box-padding: $base-line-height;
$box-border: 1px solid #ECECEC;
$box-shadow-level: 1; // value from 1 - 5, shallow - deep

//////// Image Grid Settings

$default-gap-padding: $base-line-height*0.25;

//////// Sliders

$slider-paging-alignment: center;

$slider-paging-height: 8px;
$slider-paging-width: 8px;
$slider-paging-radius: 50%;
$slider-paging-bg: $color-dark;
$slider-paging-bg-active: #fff;
$slider-paging-border: none;
$slider-paging-spacing: $base-line-height*0.25; // space between each paging button

$slider-arrow-font: 'stack-interface';
$slider-arrow-right: "\e80c";
$slider-arrow-left: "\e80b";
$slider-arrow-size: 1em;

//////// Video Styling

$play-icon-default-color: #fff;
$play-icon-filled: true;
$play-icon-width-height: $base-line-height*4;
$play-icon-border: 2px solid $play-icon-default-color;
$play-icon-triangle: 6px;

//////// Modals

$default-modal-padding: 0;

//////// Notifications

$default-notification-padding: 0;
