.page-navigator {
  position: fixed;
  padding: 0;
  top: 50%;
  transform: translateY(-50%);
  right: $base-line-height;
  z-index: 10;
}

.page-navigator ul {
  display: inline-block;
  padding: $base-line-height*0.5;
  background: rgba(0, 0, 0, 0.4);
  border-radius: $base-line-height;
  transition: all .2s ease;

  &:hover {
    background: rgba(0, 0, 0, 0.6);
  }

  li {
    &:not(:last-child) {
      margin-bottom: $base-line-height;
    }
  }
}

@media all and (max-width: 767px) {
  .page-navigator {
    right: 0;

    ul {
      border-radius: $base-line-height 0 0 $base-line-height;
      padding: $base-line-height $base-line-height*0.5;
    }
  }
}

.page-navigator li a {
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
  transition: all .2s ease;
  display: block;
  position: relative;

  &:not(:hover) {
    opacity: .5;
  }

  &.inner-link--active {
    opacity: 1;
    animation: bulge .5s ease;
    -webkit-animation: bulge .5s ease;
  }
}

@keyframes bulge {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}


.page-navigator li a[data-title] {
  &:before {
    content: attr(data-title);
    position: absolute;
    right: 12px;
    top: -14px;
    background: #222;
    color: #fff;
    border-radius: 6px;
    padding: 4px 8px;
    display: inline-block;
    transition: all .2s ease;
    white-space: nowrap;
  }

  &:not(:hover) {
    &:before {
      opacity: 0;
      transform: translateX(-20px);
    }
  }
}
