// MARKUP
//
// <div class="twitter-feed twitter-feed--slider">
//   <div class="tweets-feed" data-widget-id="492085717044981760"></div>
// </div>
//
// MODIFIERS:
// .twitter-feed--slider - make each tweet a slide
// [data-widget-id] - a uninque widget ID obtained from twitter

.progress-horizontal {
  @extend .clearfix-after;

  .progress-horizontal__bar {
    position: relative;
    overflow: hidden;
  }

  .progress-horizontal__progress {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

  .progress-horizontal__label {}
}
