.dropdown{
	position: relative;
	.dropdown__container{
		@include transition(0.3s,ease);
		opacity: 0;
		pointer-events: none;
		position: absolute;
		z-index: 999;
		.dropdown__container{
			&:before{
				height: 0;
			}
		}
	}
	.dropdown__content{
		padding: $base-line-height;
		&:not([class*='col-']){
			width: $base-line-height*10;
		}
		&:not([class*='bg-']){
			background: $color-bg-site;
		}
		&:not([class='bg--dark']){
			.menu-vertical{
				a{
					color: $color-body;
				}
			}
		}
	}
	.dropdown__trigger{
		cursor: pointer;
		user-select: none;
	}
	&.dropdown--active{
		>.dropdown__container{
			opacity: 1;
			>.container{
				>.row{
					> .dropdown__content{
						pointer-events: all;
					}
				}
			}
		}
	}
}

//Desktop
@media all and (min-width: 991px){
	.dropdown{
		.dropdown__container{
				&:before{
				height: $base-line-height*0.5;
				width: 100%;
				content: '';
				display: block;
			}
		}
		.dropdown__content{
			&.dropdown__content--md{
				padding: $base-line-height*1.5;
			}
			&.dropdown__content--lg{
				padding: $base-line-height*2;
			}
			&.dropdown__content--xlg{
				padding: $base-line-height*2.5;
			}
		}
	}
}

// Tablet Down
@media all and (max-width: 767px){
	.dropdown{
		.dropdown__container{
			min-width: 100%;
			position: relative;
			display: none;
		}
		.dropdown__content{
			padding: $base-line-height;
			left: 0 !important;
		}
		&.dropdown--active{
			>.dropdown__container{
				display: block;
			}
		}
	}	
}

// Dropdown Hovers

body.dropdowns--hover{
	.dropdown:not(.dropdown--click){
		&:hover{
			>.dropdown__container{
				&:before{
					pointer-events: all;
				}
				opacity: 1;
				.dropdown__content{
					pointer-events: all;
				}
			}
		}
	}
}

body:not(.dropdowns--hover){
	.dropdown.dropdown--hover{
		&:hover{
			>.dropdown__container{
				&:before{
					pointer-events: all;
				}
				opacity: 1;
				.dropdown__content{
					pointer-events: all;
				}
			}
		}
	}
}

// Tablet Down
@media all and (max-width: 990px){
	body.dropdowns--hover{
		.dropdown:not(.dropdown--click){
			&.dropdown--active{
				>.dropdown__container{
					&:before{
						pointer-events: all;
					}
					display: block;
				}
			}
		}
	}
}

@media all and (max-width: 767px){
	.dropdown{
		&.dropdown--absolute{
			.dropdown__container{
				position: absolute;
			}
		}
	}
}

