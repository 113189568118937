.menu-horizontal{
	> li{
		&:not(:hover){
			>a,>span,>.modal-instance>.modal-trigger{
				opacity: .5;
			}
		}
		>a,>span,>.modal-instance>.modal-trigger{
			@include transition(0.3s,ease);
			color: $color-heading;
			&:hover{
				color: $color-heading;
			}
		}
	}
}

.bg--dark{
	.menu-horizontal{
		>li{
			>a,>span{
				color: #fff;
				&:hover{
					color: #fff;
				}
			}
		}
	}
}

.menu-vertical{
	width: 100%;
	li{
		width: 100%;
		a{
			font-weight: normal;
		}
	}
}

@media all and (min-width: 990px){
	.menu-horizontal{
		display: inline-block;
		>li{
			display: inline-block;
			&:not(:last-child){
				margin-right: $base-line-height;
			}
		}
	}
	.menu-vertical{
		display: inline-block;
		li{
			white-space: nowrap;
		}
		.dropdown__container{
			top: 0;
			.dropdown__content:not([class*='bg-']){
				background: $color-bg-site;
			}
			.dropdown__content{
				transform: translateX(75%);
			}
		}
	}
}