/*! -- Stack Maps -- */

iframe[src*='google.com/maps']{
	border: none;
}

.map-container{
	min-height: 400px;
	&:not(:last-child){
		margin-bottom: $base-line-height;
	}
	&.border--round{
		overflow: hidden;
		z-index: 2;
		.gm-style{
			border-radius: 6px;
		}
	}
	iframe{
		left: 0;
	}
}

@media all and (min-width: 768px){
	.background-image-holder, .imageblock__content{
		.map-container{
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
	}
	.map-container{
		+div[class*='col-']{
			margin-top: $base-line-height*2;
		}
	}
}